import { Component, OnInit } from '@angular/core';
import { PrevisaoCearaService } from 'src/app/core/services/previsao-ceara.service';
import { baseref } from 'src/environments/environment';

@Component({
  selector: 'app-previsao-mini',
  templateUrl: './previsao-mini.component.html',
  styleUrls: ['./previsao-mini.component.scss']
})
export class PrevisaoMiniComponent implements OnInit {

  public baseref = baseref;
  public loading: boolean = false;
  public previsoes = [];
  public day: Date;
  public weekDays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']
  public previsaoDias = [];
  public dataCadastro;
  public dataPrevisao;
  public popup: boolean = false;

  constructor(private previsaoCeara: PrevisaoCearaService) {
    this.previsaoCeara.serviceListener$.subscribe(
      result => {
        this.previsoes = result.data.list;
        this.day = new Date();
        if (this.day.getDate() != new Date(this.previsoes[0].created_at).getDate()) {
          this.previsoes.pop();
          this.previsoes.reverse();
        } else {
          this.previsoes.reverse();
          this.previsoes.pop();
        }
        let day = this.day.getDay()
        this.previsaoDias.push(this.weekDays[day])
        day = (day + 1 > 6) ? 0 : day + 1;
        this.previsaoDias.push(this.weekDays[day])
        day = (day + 1 > 6) ? 0 : day + 1;
        this.previsaoDias.push(this.weekDays[day])
        this.dataCadastro = this.previsoes[0].created_at;
        this.dataPrevisao = this.previsoes[0].data_previsao;

        this.previsoes[0].previsao_icone.forEach(icone => {
          let icon = document.getElementById((icone.matriz_coluna_x - 1) + "_" + (icone.matriz_coluna_y - 1));
          icon.setAttribute('style', 'background-image: url("'+this.baseref+'/assets/img/icones_previsao/' + icone.imagem + '.gif"); background-size: 30px 30px;')
        });
        this.loading = false;
      });
  }

  ngOnInit() {
    this.previsaoCeara.get();
    this.loading = true;
    document.getElementsByClassName('mapa-background')[0].setAttribute('style', 'background-image: url("'+this.baseref+'/assets/img/ceara.png"');
  }

  info() {
    this.popup = !this.popup
  }
}
