import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';;
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrevisaoCearaService {

  //Requisição antiga via apil5
  protected host = "https://apil5.funceme.br/";
  protected uri = "rest/tempo/previsao-diaria?";
  protected params = "orderBy=id,DESC&limit=4&with=previsaoAnalise,previsaoIcone";
  
  // protected host = "https://api1.funceme.br/";
  // protected uri = "rest/tempo/previsaodiaria?";
  // protected params = "paginator=true&limit=4&page=0&response_format=apil5&cache=yes&convert_geometry=false&convert_geometry_fk=false&use_fk=true&include_all_obj_fk=true";

  private serviceListener = new Subject<any>();
  public serviceListener$ = this.serviceListener.asObservable();

  constructor(private http: HttpClient) { }

  public get(callback?, errorHandler?): void {
    this.http.get(this.host + this.uri + this.params).subscribe((response) => {
      if (callback) {
        callback(response);
      }
      this.serviceListener.next(response);
    }, (error) => {
      if (errorHandler) {
        errorHandler(error);
      }
    });
  }
}
