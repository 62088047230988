import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor 
{
    constructor() { 
        localStorage.setItem('access_token', 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjU1NjQwNjQ5YmRmNjc4OGY2ZGU1NGUwN2U3MGY4MWE5MzNlZmMyMzdiZWJjNWMxNzYyMjI2ODMxYzliYTY0NjdmOTFmNTQ3MzAzMTk3OTJhIn0.eyJhdWQiOiI1IiwianRpIjoiNTU2NDA2NDliZGY2Nzg4ZjZkZTU0ZTA3ZTcwZjgxYTkzM2VmYzIzN2JlYmM1YzE3NjIyMjY4MzFjOWJhNjQ2N2Y5MWY1NDczMDMxOTc5MmEiLCJpYXQiOjE1NTMyNTQyMDksIm5iZiI6MTU1MzI1NDIwOSwiZXhwIjoxNTg0ODc2NjA5LCJzdWIiOiIiLCJzY29wZXMiOltdfQ.XTK31d9jx4ZAS-z6lE_akWT9dyIiFKq2K3434_yAAP-zTKfrj5i7q0_9nodt-ClSchl2gI12N9BybGbit77-DBPNKJ0DVYdPskOUslHnZN7929me6yawlOknPwFNWiS6ohzVL8-v84VPNbHRcn12pOy6Est-8pSfqmAUX2sNUw0_dBV5r-BRZ5_0fMh40_P3gHY_Rb0vzonFo3ut72lVVMdIIlR0hd3HPYi9qRS7IIw9zt0RI7iSYr6ngcryG-z3sTvAMHfQ3vWoyB-XXbum0otDFwCtO7kPTdiU2ZFSZBLeBFHhAlXzQzHai1U3KJ1zRpZLAb4jg8z_iKhB5BRVdbI1P746_iyPs8gUIEdFvP0XwE77jzGh3Wc5g3iNFJUyoRrjp4VLDpTUNZskHgKnEDkHAwL9z7LYs8bRpb-KVFytana7w_j2_kMkkPgwJFq6eA96oi4b31qXfMljueqoC9TZzOXdul_h6NEIlXCicim3_grVG8wHiLgiSLXhfXwfKGD0xCLF2F4MpL1FVVVOMpG_TmtB28-urg_o9Ci5WPvScaI1fOaAso5mExGIxS6A2rTDmeeoljhBnLAYTKrcWvk6A5teR0B1dzE62CWx0fqWfcJ1n_MFDvlcwPdZ51X-76x8TxsXn9spR19f_85n_o9cdFLc4wTE5zJfr-npVjs');
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> 
    {
        const token = localStorage.getItem('access_token');

        if (token != null) {
            request = request.clone({
            setHeaders: {
                Authorization: token
            }
            });
        }
        return next.handle(request);
    }
}
