import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RootComponent } from './core/app-layout/root/root.component';
import { PrevisaoCearaComponent } from './content/previsao-ceara/previsao-ceara.component';
import { PrevisaoMiniComponent } from './content/previsao-mini/previsao-mini.component';
import { PrevisaoMunicipioComponent } from './content/previsao-municipio/previsao-municipio.component';

const routes: Routes = [
    {
        path: '',
        component: RootComponent,
        children: [
            {
                path: '',
                redirectTo: 'previsao-municipio',
                pathMatch: 'full'
            },
            { path: 'previsao-municipio', component: PrevisaoMunicipioComponent },
            { path: 'previsao-ceara', component: PrevisaoCearaComponent },
            { path: 'previsao-ceara/mini', component: PrevisaoMiniComponent }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
