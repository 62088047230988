import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { AppLayoutModule } from './core/app-layout/app-layout.module';
import { HomeModule } from './content/home/home.module';
import { JwtModule } from '@auth0/angular-jwt';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { GlobalApp } from './shared/global';
import { MAT_DATE_LOCALE, MatProgressBarModule } from '@angular/material';
import { PrevisaoMunicipioComponent } from './content/previsao-municipio/previsao-municipio.component';
import { PrevisaoCearaComponent } from './content/previsao-ceara/previsao-ceara.component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { PrevisaoMiniComponent } from './content/previsao-mini/previsao-mini.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';


export function tokenGetter() {
    return localStorage.getItem('access_token');
}

@NgModule({
    declarations: [
        AppComponent,
        PrevisaoMunicipioComponent,
        PrevisaoCearaComponent,
        PrevisaoMiniComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AppLayoutModule,
        HttpClientModule,
        BrowserAnimationsModule,
        LayoutModule,
        HomeModule,
        MatCardModule,
        MatDividerModule,
        MatButtonModule,
        MatIconModule,
        MatProgressBarModule,
        MatTooltipModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter
            }
        }),
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'pt' },
        GlobalApp,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
