export class GlobalApp 
{
    public base_url = 'http://localhost:8000/';
    //public base_url = 'http://apil5.funceme.br/';
    public client_id = 2;
    public client_secret = "tKwntsy5g7tYYQt7uvPb6LJu8eSm365bJG5Is6HP";
}

export enum ButtonType {
    Default = 1,
    Raised = 2,
    Fab = 3,
    MiniFab = 4
}
