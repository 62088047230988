import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

    // @ViewChild('sidenav') sidenav: MatSidenav;
    
    constructor(private router: Router) 
    {
    }

    close() {
        // this.sidenav.close();
    }

    ngOnInit() {
    
    }

    public link(url:string) {
        this.router.navigate([url]);
    }
}
