import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';;
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrevisaoMunicipioService {


  protected host = "https://apil5.funceme.br/";
  protected uri = "rest/tempo/previsao-meteorologista?";
  protected params = "orderBy=id,DESC&limit=4";

  // protected host = "https://api1.funceme.br/";
  // protected uri = "rest/tempo/previsaometeorologista?";
  // protected params = "paginator=true&limit=4&page=0&response_format=apil5&orderby=id,desc";

  private serviceListener = new Subject<any>();
  public serviceListener$ = this.serviceListener.asObservable();

  constructor(private http: HttpClient) { }

  public get(callback?, errorHandler?): void {
    this.http.get(this.host + this.uri + this.params).subscribe((response) => {
      if (callback) {
        callback(response);
      }
      this.serviceListener.next(response);
    }, (error) => {
      if (errorHandler) {
        errorHandler(error);
      }
    });
  }

}
