import { Component, OnInit } from '@angular/core';
import { PrevisaoMunicipioService } from 'src/app/core/services/previsao-municipio.service';
import { baseref } from 'src/environments/environment';

@Component({
  selector: 'app-previsao-municipio',
  templateUrl: './previsao-municipio.component.html',
  styleUrls: ['./previsao-municipio.component.scss']
})
export class PrevisaoMunicipioComponent implements OnInit {

  public baseref = baseref;
  public loading: boolean = false;
  public previsoes = [];
  public day: Date;
  public weekDays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']
  public previsaoDias = [];

  public dataAtualizacao: string = '00:00:00';
  public dataCached: String = '00/00/0000 00:00:00';
  public dateQueueIn: Date = new Date();

  constructor(private previsaoService: PrevisaoMunicipioService) {
    this.previsaoService.serviceListener$.subscribe(
      result => {
        /*let date = new Date(result.meta.cache.cached_at.date.slice(0,19));
        this.dataCached = date.toLocaleString('pt-BR');

        let queueIn = result.meta.cache.queue_in;
        let expiresIn = result.meta.cache.expires_in;

        if (queueIn > expiresIn)
          queueIn = expiresIn;
        if (queueIn < 0)
          queueIn = 30;

        this.dateQueueIn.setSeconds(this.dateQueueIn.getSeconds() + queueIn);*/
        
        this.previsoes = result.data.list;
        this.day = new Date();
        if (this.day.getDate() != new Date(this.previsoes[0].data_cadastro).getDate()) {
          this.previsoes.pop();  
          this.previsoes.reverse();
        } else {
          this.previsoes.reverse();          
          this.previsoes.pop();
        }        
        this.previsaoDias.push('Hoje')
        let day = this.day.getDay()
        day = (day + 1 > 6) ? 0 : day + 1;
        this.previsaoDias.push(this.weekDays[day])
        day = (day + 1 > 6) ? 0 : day + 1;
        this.previsaoDias.push(this.weekDays[day])
        this.loading = false;
      });
  }

  ngOnInit() {
    this.previsaoService.get();
    this.loading = true;
    this.autoRefresh();
  }

  private autoRefresh()
  {
    /*setInterval(()=>{
      if(this.dateQueueIn && !this.loading){
        let queueInTime = this.dateQueueIn.getTime();
        
        if (queueInTime > Date.now()) {
          let time = (queueInTime - Date.now());
          this.dataAtualizacao = new Date(time).toISOString().substr(11, 8);
        }

        if (queueInTime < Date.now())
          this.previsaoService.get();
      }
    },1000);*/
  }

}
