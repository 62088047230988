import { NgModule }                 from '@angular/core';
import { CommonModule }             from '@angular/common';
import { AppRoutingModule }         from '../../app-routing.module';
import { RootComponent }            from './root/root.component';
import { NavigationComponent }      from './navigation/navigation.component';
import { FooterComponent }          from './footer/footer.component';
import { BrowserAnimationsModule }  from '@angular/platform-browser/animations';
import {
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule, 
    MatIconModule,
    MatDialogModule, 
    MatSidenavModule, 
    MatListModule
} from '@angular/material';
import { HeaderComponent }  from './header/header.component';
import { LayoutModule }     from '@angular/cdk/layout';
import { SideNavComponent } from './side-nav/side-nav.component';

@NgModule({
    declarations: [
        RootComponent,
        HeaderComponent,
        NavigationComponent, 
        FooterComponent, SideNavComponent, 
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatSidenavModule,
        LayoutModule,
        MatListModule
    ],
    exports: [
    ]
})
export class AppLayoutModule { }
