import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent{

    // @ViewChild('sidenav') sidenav: MatSidenav;

    reason = '';
  
    close(reason: string) {
        this.reason = reason;
        // this.sidenav.close();
    }
  
    shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));

}
