import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { PrevisaoCearaService } from 'src/app/core/services/previsao-ceara.service';
import { baseref } from 'src/environments/environment';

@Component({
  selector: 'app-previsao-ceara',
  templateUrl: './previsao-ceara.component.html',
  styleUrls: ['./previsao-ceara.component.scss']
})
export class PrevisaoCearaComponent implements OnInit {

  public baseref = baseref;
  public loading: boolean = false;
  public previsoes = [];
  public day:Date;
  public weekDays = ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado']
  public previsaoDias = [];
  public dataCadastro;
  public dataPrevisao;
  public dataAnalise;
  public textoAnalise;
  public imgAnalise;
  public showLegenda: boolean = false;

  public dataAtualizacao: string = '00:00:00';
  public dataCached: String = '00/00/0000 00:00:00';
  public dateQueueIn: Date = new Date();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    let popup = document.getElementsByClassName('legenda-card')[0]
    let overlay = document.getElementsByClassName('overlay')[0]
    
    if (!overlay.contains(event.target)) return;

    if(!popup.contains(event.target)) {
      this.showLegenda = false;
    }
  }

  constructor(private previsaoCeara: PrevisaoCearaService, private eRef: ElementRef) {
    this.previsaoCeara.serviceListener$.subscribe(
      result => {
        /*let date = new Date(result.meta.cache.cached_at.date.slice(0,19));
        this.dataCached = date.toLocaleString('pt-BR');

        let queueIn = result.meta.cache.queue_in;
        let expiresIn = result.meta.cache.expires_in;

        if (queueIn > expiresIn)
          queueIn = expiresIn;
        if (queueIn < 0)
          queueIn = 30;

        this.dateQueueIn.setSeconds(this.dateQueueIn.getSeconds() + queueIn);*/
        
        this.previsoes = result.data.list;
        this.day = new Date();
        if (this.day.getDate() != new Date(this.previsoes[0].created_at).getDate()) {
          this.previsoes.pop();  
          this.previsoes.reverse();
        } else {
          this.previsoes.reverse();          
          this.previsoes.pop();
        }
        let day = this.day.getDay()
        this.previsaoDias.push(this.weekDays[day])
        day = (day + 1 > 6) ? 0 : day + 1;
        this.previsaoDias.push(this.weekDays[day])
        day = (day + 1 > 6) ? 0 : day + 1;
        this.previsaoDias.push(this.weekDays[day])
        this.dataCadastro = this.previsoes[0].created_at;
        this.dataPrevisao = this.previsoes[0].data_previsao;
        

        this.textoAnalise = this.previsoes[0].previsao_analise[0].descricao; 
        this.imgAnalise = this.previsoes[0].previsao_analise[0].link_imagem;
        this.dataAnalise = this.previsoes[0].previsao_analise[0].created_at;
        
        this.previsoes[0].previsao_icone.forEach(icone => {
            let icon = document.getElementById((icone.matriz_coluna_x-1)+"_"+(icone.matriz_coluna_y-1));
            icon.setAttribute('style','background-image: url("'+this.baseref+'/assets/img/icones_previsao/' + icone.imagem + '.gif"); background-size: 50px 50px;')
        });
        this.loading = false;
      });
  }

  ngOnInit() {
    this.previsaoCeara.get();   
    this.loading = true;
    document.getElementsByClassName('mapa-background')[0].setAttribute('style', 'background-image: url("'+this.baseref+'/assets/img/mapa_ceara_verde.png"');

    //this.autoRefresh();
  }

  private autoRefresh()
  {
    setInterval(()=>{
      if(this.dateQueueIn && !this.loading){
        let queueInTime = this.dateQueueIn.getTime();
        
        if (queueInTime > Date.now()) {
          let time = (queueInTime - Date.now());
          this.dataAtualizacao = new Date(time).toISOString().substr(11, 8);
        }

        if (queueInTime < Date.now())
          this.previsaoCeara.get();
      }
    },1000);
  }

  toggleLegenda() {
    this.showLegenda = !this.showLegenda;
  }

}
